<script>
import Layout from "../../layouts/main";
import Swal from "sweetalert2";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import UserService from "@/services/UserService";
import {  mapState } from "vuex";

const userService = new UserService();
/**
 * User grid component
 */
export default {
  page: {
    title: "Usuarios",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },
    computed: {
    /**
     * Total no. of records
     */
  ...mapState('auth', ['currentUser']),
  
  },
   created(){

    this.chargeItems();
  },
  data() {
    return {
      title: "Usuarios al sistema",
      items: [
        {
          text: "Inicio",
        },
        {
          text: "Usuario al sistema",
          active: true,
        },
      ],
      
      invoicelist: [
        
      ],
      gridList: [
       
      ],
    };
  },
  methods: {
    /**
     * Search the table data with search input
     */
    chargeItems(){
        userService.getList().then(response=>{
          this.invoicelist = response.data.users;
        });
    },
    
    routeGo(textRoute){
      this.$router.push({
        path: textRoute,
      });
    },
    deleteElement(id){
      userService.delete(id).then(()=>{
         this.successForm();
         this.chargeItems();
      })
    },
    successForm(){
      Swal.fire("¡Operación exitosa!", "El registro fué eliminado", "success");
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div
        class="col-xl-3 col-sm-6"
       
      >
        <div class="card text-center complete-card">
          <div class="card-body custom">
          
            <div class="clearfix"></div>
            <div class="mb-4">
             
              <div class="avatar-lg mx-auto mb-4">
                <div
                  class="avatar-title bg-soft-primary rounded-circle text-primary"
                >
                  <a  @click="routeGo('/usuarios/create')"><i
                    class="uil-user-plus display-4 m-0 text-primary"
                  ></i></a>
                </div>
              </div>
            </div>
            <h5 class="font-size-16 mb-1">
              <a  @click="routeGo('/usuarios/create')" class="text-dark"> Añadir usuario</a>
            </h5>
           
          </div>
        </div>
      </div>


      <div
        class="col-xl-3 col-sm-6"
        v-for="(item, index) in invoicelist"
        :key="index"
      >
        <div class="card text-center complete-card">
          <div class="card-body">
            <!--<b-dropdown
              class="float-end"
              variant="white"
              menu-class="dropdown-menu-end"
              right
              toggle-class="font-size-16 text-body p-0"
            >
              <template v-slot:button-content>
                <i class="uil uil-ellipsis-h"></i>
              </template>
              <a class="dropdown-item" href="#">Edit</a>
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Remove</a>
            </b-dropdown>-->
            <div class="clearfix"></div>
            <div class="mb-4">
              <img
                v-if="item.profile"
                :src="item.profile"
                alt
                class="avatar-lg rounded-circle img-thumbnail"
              />
              <div class="avatar-lg mx-auto mb-4" v-if="!item.profile">
                <div
                  class="avatar-title bg-soft-primary rounded-circle text-primary"
                >
                  <i
                    class="mdi mdi-account-circle display-4 m-0 text-primary"
                  ></i>
                </div>
              </div>
            </div>
            <h5 class="font-size-16 mb-1">
              <a href="#" class="text-dark">{{ item.name }}</a>
            </h5>
            <p class="text-muted mb-2">{{ item.email }}</p>
          </div>

          <div class="btn-group" role="group">
            <button type="button" class="btn btn-outline-light text-truncate" @click="routeGo('/usuarios/edit/'+item.id)">
              <i class="uil uil-pen me-1"></i> Editar
            </button>
            <button type="button" class="btn btn-outline-light text-truncate"  @click="deleteElement(item.id)">
              <i class="uil uil-trash-alt me-1"></i> Eliminar
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <!--<div class="row mt-3">
      <div class="col-xl-12">
        <div class="text-center my-3">
          <a href="javascript:void(0);" class="text-primary">
            <i
              class="mdi mdi-loading mdi-spin font-size-20 align-middle me-2"
            ></i>
            Load more
          </a>
        </div>
      </div>
    </div>-->
    <!-- end row -->
  </Layout>
</template>
